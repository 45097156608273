<template>
  <div style="text-align:center">
    <van-row style="margin-top:50px">
      <svg-icon data_iconName="baojing" :className="'item_icon'" />
    </van-row>
    <van-row style="font-size:14px;color:grey;margin-top:30px">
      最近派出所电话
    </van-row>
    <van-row style="margin-top:20px">
      <span style="font-size:30px;" v-if="phones.length == 0">
        {{poi.tel == null || poi.tel == undefined? '无': poi.tel}}
      </span>
      <van-col span="24" v-for="item, idx in phones" :key="idx">
        <span style="font-size:30px;">
          {{item}}
        </span>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="24">
        <span style="color:#666;font-size:14px;">
          {{poi.name == null || poi.name == undefined? '': poi.name}}
        </span>
      </van-col>
      <van-col span="24">
        <span style="color:#666;font-size:14px;" v-if="poi.address">
          地址：{{poi.address}}
        </span>
      </van-col>
    </van-row>
    <div class="action_btn">
      <van-row>
        <van-col span="12">
          <van-button type="default" round style="width:90%" @click="onCancel()">取消</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="info" round style="width:90%" 
            :color="poi.tel == null || poi.tel == undefined? '#666666':'#395EE8'" 
            @click="callPhone()" 
            :disabled="poi.tel == null || poi.tel == undefined">立即呼叫</van-button>
        </van-col>
      </van-row>
    </div>
    <van-action-sheet
      v-model="showPhonePick"
      :actions="phoneList"
      cancel-text="取消"
      close-on-click-action
      @cancel="showPhonePick = false"
      @select="selectCall"
    />
  </div>
</template>

<script>
export default {
  props: {
    poi: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      phones: [],
      showPhonePick: false,
      phoneList: []
    }
  },
  watch: {
    'poi': {
      handler(val) {
        const _self = this
        _self.phones = []
        _self.phoneList = []
        if(val.tel.indexOf(';') > -1) {
          val.tel.split(';').forEach(ele => {
            _self.phones.push(ele)
            _self.phoneList.push({name: ele})
          })
        }
      }
    }
  },
  methods: {
    callPhone() {
      if(this.phones.length == 0) {
        window.location.href = `tel://${this.poi.tel}`
      } else {
        this.showPhonePick = true
      }
    },
    onCancel() {
      this.$emit('cancel-baojing', true)
    },
    selectCall(val) {
      window.location.href = `tel://${val.name}`
    }
  }
}
</script>

<style scoped>
.action_btn {
  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0;
}
</style>